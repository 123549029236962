import React, { useState, useEffect } from "react";
import FormField from "components/shared/formfield/FormField";
import { useFormContext } from "react-hook-form";
import FormFieldCheckbox from "components/shared/form/FormFieldCheckbox";
import FormFieldSelect from "components/FormFieldSelect";
import { billTo } from "data/billToTypes";

const PUBLIC_ASSETS_BASE_URL = process.env?.REACT_APP_PUBLIC_ASSETS_URL;

export default function AttributeValueInputs({}) {
  const { watch, register, control, errors, setValue } = useFormContext();

  const [isPrisidioBranded, setIsPrisidioBranded] = useState();
  const [allowPublicRegistration, setAllowPublicRegistration] = useState(false);

  const billToValue = watch("billingRule.billTo");

  function handlePrisidioBrandedClick() {
    setIsPrisidioBranded(!isPrisidioBranded);
  }

  function handleAllowRegistrationClick() {
    setAllowPublicRegistration(!allowPublicRegistration);
  }

  useEffect(() => {
    if (billToValue?.value !== billTo?.[1]?.value) {
      setValue("billingRule.orgBillPeriodDays", null);
    }
  }, [billToValue]);

  useEffect(() => {
    if (!isPrisidioBranded) return;
    setValue("logoUrl", `${PUBLIC_ASSETS_BASE_URL}/prisidio-logo.png`);
    setValue(
      "smallLogoUrl",
      `${PUBLIC_ASSETS_BASE_URL}/prisidio-logosmall-zach-test.png`
    );
    setValue("primaryColor", "#2742f5");
    setValue("pageBackgroundColor", "#f9f9ff");
  }, [isPrisidioBranded]);

  return (
    <>
      <FormField
        name="name"
        type="text"
        label="Name"
        placeholder="kozco-org"
        size="normal"
        id="organization-add-name"
        defaultValue={null}
        description="REQUIRED. This is any human-readable identifier for the organization that will be used by end-users to direct them to their organization. Use hyphen-case and lowercase letters. Only 1 hyphen allowed."
        error={errors.name}
        inputRef={register({
          required: "Please enter a name",
        })}
      />

      <FormField
        name="displayName"
        type="text"
        label="Display Name"
        placeholder="Koz Co"
        size="normal"
        id="organization-add-display-name"
        defaultValue={null}
        description="REQUIRED. This is the name that will be displayed to end-users for this organization."
        error={errors.displayName}
        inputRef={register({
          required: "Please enter a display name",
        })}
      />

      <div className="is-flex pb-4">
        <FormFieldCheckbox
          name="allowPublicRegistration"
          label="Allow Public Registration"
          description=""
          checked={allowPublicRegistration}
          onChange={handleAllowRegistrationClick}
          id="organization-add-allow-public-registration-checkbox"
          inputRef={register}
        />
      </div>

      <hr />
      <div className="is-flex pb-4">
        <FormFieldCheckbox
          name="isPrisidioBranded"
          label="Use Prisidio Branding"
          description=""
          checked={isPrisidioBranded}
          onChange={handlePrisidioBrandedClick}
          id="organization-add-is-prisidio-branded-checkbox"
          inputRef={register}
        />
      </div>

      <FormField
        name="logoUrl"
        type="text"
        label="Logo Url"
        placeholder={`${PUBLIC_ASSETS_BASE_URL}/kozapproved.png`}
        size="normal"
        id="organization-add-logo-url"
        defaultValue={null}
        description="REQUIRED. This is the logo that will be displayed to end-users within this organization. The URL must use https"
        error={errors.logoUrl}
        inputRef={register({
          required: "Please enter a logo url",
        })}
        disabled={isPrisidioBranded}
      />

      <FormField
        name="smallLogoUrl"
        type="text"
        label="Small Logo Url"
        placeholder={`${PUBLIC_ASSETS_BASE_URL}/kozapproved.png`}
        size="normal"
        id="organization-add-small-logo-url"
        defaultValue={null}
        description="This will be displayed in mobile's vault selector. This should have an aspect ratio of 1:1. The URL must use https"
        error={errors.smallLogoUrl}
        inputRef={register({
          required: "Please enter a logo url",
        })}
        disabled={isPrisidioBranded}
      />

      <FormField
        name="primaryColor"
        type="text"
        label="Primary Color"
        placeholder="#ff0000"
        size="normal"
        id="organization-add-primary-color"
        defaultValue={null}
        description="REQUIRED. This is the primary color that will be displayed to end-users for this organization. Enter a full Hex value, including #."
        error={errors.primaryColor}
        inputRef={register({
          required: "Please enter a primary color",
        })}
        disabled={isPrisidioBranded}
      />

      <FormField
        name="pageBackgroundColor"
        type="text"
        label="Secondary / Background Color"
        placeholder="#000000"
        size="normal"
        id="organization-add-secondary-color"
        defaultValue={null}
        description="REQUIRED. This is the secondary / background color that will be displayed to end-users for this organization. Enter a full Hex value, including #."
        error={errors.pageBackgroundColor}
        inputRef={register({
          required: "Please enter a secondary color",
        })}
        disabled={isPrisidioBranded}
      />

      <FormField
        name="backgroundImageUrl"
        type="text"
        label="Background Image Url"
        placeholder={`${PUBLIC_ASSETS_BASE_URL}/kozapproved.png`}
        size="normal"
        id="organization-add-background-image-url"
        defaultValue={null}
        description="REQUIRED. This is the background image that will be displayed on the login scree. The URL must use https"
        error={errors.backgroundImageUrl}
        inputRef={register}
        disabled={isPrisidioBranded}
      />

      <FormField
        name="slogan"
        type="text"
        label="Slogan"
        placeholder="Simple, Mobile, Secure"
        size="normal"
        id="organization-add-slogan"
        defaultValue={null}
        description="Slogan that will appear on the login screen."
        error={errors.slogan}
        inputRef={register}
        disabled={isPrisidioBranded}
      />

      <FormField
        name="sloganBackgroundColor"
        type="text"
        label="Slogan Background Color"
        placeholder="#000000"
        size="normal"
        id="organization-add-slogan-color"
        defaultValue={null}
        description="Color that will appear behind the slogan text. Please use a value that works well with white text."
        error={errors.sloganBackgroundColor}
        inputRef={register}
        disabled={isPrisidioBranded}
      />

      <hr />

      <FormField
        name="effectiveDate"
        type="date"
        label="Effective Date"
        placeholder=""
        size="normal"
        id="organization-add-effective-date"
        defaultValue={null}
        error={errors.effectiveDate}
        inputRef={register({
          message:
            "Please enter a valid date in the following format, XX/XX/XXXX.",
        })}
      />

      <FormField
        name="endDate"
        type="date"
        label="End Date"
        placeholder=""
        size="normal"
        id="organization-add-end-date"
        defaultValue={null}
        description="OPTIONAL. What date will this organization not be available? Defaults to 12:00AM UTC of the day chosen."
        error={errors.endDate}
        inputRef={register({
          message:
            "Please enter a valid date in the following format, XX/XX/XXXX.",
        })}
      />

      <hr />

      <FormFieldSelect
        name="billingRule.billTo"
        options={billTo}
        label="Bill To"
        size="normal"
        id="organization-add-bill-to"
        error={errors?.billingRule?.billTo}
        defaultValue={billTo[0]}
        control={control}
        setValue={setValue}
      />

      <FormField
        name="billingRule.orgBillPeriodDays"
        type="number"
        min={0}
        label="Org Bill Period"
        placeholder=""
        size="normal"
        id="organization-add-billperiod-days"
        defaultValue={null}
        disabled={billToValue?.value !== billTo?.[1]?.value}
        description="OPTIONAL. The number of days before the customer starts getting billed."
        error={errors?.billingRule?.orgBillPeriodDays}
        inputRef={register}
      />

      <FormField
        name="subscriptionProductId"
        type="text"
        label="Subscription Product Id"
        placeholder=""
        size="normal"
        id="organization-add-product-id"
        defaultValue={null}
        description="Stripe Product Id"
        error={errors.subscriptionProductId}
        inputRef={register({
          required: "Please enter a Subscription Product Id",
        })}
      />

      <FormField
        name="subscriptionPriceIds.0"
        type="text"
        label="Subscription Price Id #1"
        placeholder=""
        size="normal"
        id="organization-add-price-id-0"
        defaultValue=""
        description="Stripe Price Id"
        error={errors?.subscriptionPriceIds?.[0]}
        inputRef={register({
          required: "Please enter at least one subscriptionPriceId",
        })}
      />

      <FormField
        name="subscriptionPriceIds.1"
        type="text"
        label="Subscription Price Id #2"
        placeholder=""
        size="normal"
        id="organization-add-price-id-1"
        defaultValue=""
        description="Stripe Price Id"
        error={errors?.subscriptionPriceIds?.[1]}
        inputRef={register}
      />
      <FormField
        name="subscriptionPriceIds.2"
        type="text"
        label="Subscription Price Id #3"
        placeholder=""
        size="normal"
        id="organization-add-price-id-2"
        defaultValue=""
        description="Stripe Price Id"
        error={errors?.subscriptionPriceIds?.[2]}
        inputRef={register}
      />
      <FormField
        name="subscriptionPriceIds.3"
        type="text"
        label="Subscription Price Id #4"
        placeholder=""
        size="normal"
        id="organization-add-price-id-3"
        defaultValue=""
        description="Stripe Price Id"
        error={errors?.subscriptionPriceIds?.[3]}
        inputRef={register}
      />

      <hr />

      <FormField
        name="customDns"
        type="text"
        label="Custom DNS"
        placeholder="koz.prisid.io"
        size="normal"
        id="organization-add-custom-dns"
        defaultValue={null}
        description="Custom DNS for the Org.  Will generate signup and login urls in the API.  Do not include https://www"
        error={errors.customDns}
        inputRef={register({
          required: "Please enter a Custom DNS value",
        })}
      />

      <FormField
        name="clientId"
        type="text"
        label="ClientId"
        placeholder=""
        size="normal"
        id="organization-add-client-id"
        defaultValue={null}
        description="Provided by Auth0 when an app is created for the organization"
        error={errors.clientId}
        inputRef={register}
      />
    </>
  );
}
