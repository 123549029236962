import React from "react";
import Panel from "components/shared/panel/Panel";
import moment from "moment";
import styles from "./Details.module.scss";
import TextButton from "components/shared/button/TextButton";
import { history } from "util/router";

export default function OrganizationDetails({ organization, orgId }) {
  const details = [
    { label: "Display Name", value: organization?.displayName },
    { label: "Name", value: organization?.name },
    {
      label: "Public Registration Allowed",
      value: `${organization?.allowPublicRegistration}`,
      hasDivider: true
    },
    {
      label: "orgId",
      value: organization?.orgId,
    },
    {
      label: "Auth0 orgId",
      value: organization?.idmOrgId,
      hasDivider: true,
    },
    { label: "Is Active", value: `${organization?.isActive}` },
    {
      label: "Uses Prisidio Branding",
      value: `${organization?.isPrisidioBranded}`,
      hasDivider: true,
    },
    {
      label: "Effective Date",
      value: moment.unix(organization?.effectiveDate).format("l"),
    },
    {
      label: "End Date",
      value:
        organization?.endDate && moment.unix(organization?.endDate).format("l"),
      hasDivider: true,
    },
    {
      label: "Bill To",
      value: organization?.billingRule?.billTo,
    },
    {
      label: "Org Bill Period",
      value: organization?.billingRule?.orgBillPeriodDays,
    },
    {
      label: "Subscription Price Id #1",
      value: organization?.subscriptionPriceIds?.[0] ?? organization?.subscriptionPriceId,
    },
    {
      label: "Subscription Price Id #2",
      value: organization?.subscriptionPriceIds?.[1],
    },
    {
      label: "Subscription Price Id #3",
      value: organization?.subscriptionPriceIds?.[2],
    },
    {
      label: "Subscription Price Id #4",
      value: organization?.subscriptionPriceIds?.[3],
    },
    {
      label: "Subscription Product Id",
      value: organization?.subscriptionProductId,
      hasDivider: true,
    },
    {
      label: "Custom DNS",
      value: organization?.customDns,
    },
    {
      label: "Client Id",
      value: organization?.clientId,
    },
  ];
  const brandDetails = [
    {
      label: "Logo",
      value: organization?.brandDetails?.logoUrl,
      showImage: true,
    },
    {
      label: "Small Logo",
      value: organization?.brandDetails?.smallLogoUrl,
      showImage: true,
    },
    {
      label: "Background Image",
      value: organization?.brandDetails?.backgroundImageUrl,
      showImage: true,
    },
    {
      label: "Primary Color",
      value: organization?.brandDetails?.primaryColor,
      showColor: true,
    },
    {
      label: "Page Background Color / Secondary Color",
      value: organization?.brandDetails?.pageBackgroundColor,
      showColor: true,
    },
    { label: "Slogan", value: organization?.brandDetails?.slogan },
    {
      label: "Slogan Background Color",
      value: organization?.brandDetails?.sloganBackgroundColor,
      showColor: true,
    },
  ];

  return (
    <>
      <div className="columns">
        <div className="column">
          <div className="tile is-vertical mt-3">
            <Panel>
              <article className="tile is-child is-white p-3 is-relative">
                <div className={styles.editButton}>
                  <TextButton
                    icon="edit"
                    id="organization-edit-details-btn"
                    onClick={() => {
                      history.push(`/organizations/${orgId}/edit`);
                    }}
                  />
                </div>

                <h3 className="pb-3">Details</h3>
                <div className="pl-3">
                  {details.map((info, index) => (
                    <div className="field pb-3" key={`${info}${index}`}>
                      <label className="label has-text-size-normal mb-1">
                        {info.label}
                      </label>
                      <div className="control">
                        <p
                          className={`has-text-karman100 is-font-size-large has-text-weight-medium ${styles.value}`}
                        >
                          {!!info.value ? info.value : "--"}
                        </p>
                      </div>
                      {!!info?.hasDivider && <hr />}
                    </div>
                  ))}
                </div>
              </article>
            </Panel>
          </div>
        </div>
        <div className="column">
          <div className="tile is-vertical mt-3">
            <Panel>
              <article className="tile is-child is-white p-3 is-relative">
                <div className={styles.editButton}>
                  <TextButton
                    icon="edit"
                    id="organization-edit-details-btn"
                    onClick={() => {
                      history.push(`/organizations/${orgId}/edit`);
                    }}
                  />
                </div>

                <h3 className="pb-3">Brand Details</h3>
                <div className="pl-3">
                  {brandDetails.map((info, index) => (
                    <div className="field pb-3" key={`${info}${index}`}>
                      <label className="label has-text-size-normal mb-1">
                        {info.label}
                      </label>
                      <div className="control">
                        {!info?.showImage ? (
                          <p
                            className={`has-text-karman100 is-font-size-large has-text-weight-medium ${styles.value}`}
                          >
                            {info?.showColor && (
                              <span
                                className={styles.colorBox}
                                style={{
                                  backgroundColor: info.value,
                                }}
                              ></span>
                            )}{" "}
                            {!!info.value ? info.value : "--"}
                          </p>
                        ) : (
                          <>
                            <img
                              className={styles.previewImage}
                              src={info?.value}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </article>
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
}
