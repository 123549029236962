import React, { useState, useEffect } from "react";
import Panel from "components/shared/panel/Panel";
import FormField from "components/shared/formfield/FormField";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { updateOrganization } from "stores/organizationsStore";
import { history } from "util/router.js";
import styles from "components/organizations/organization/Organization.module.scss";
import Message from "components/shared/toast/Message";
import Button from "components/shared/button/Button";
import { middleEllipsis } from "util/helpers";
import moment from "moment";
import { useDebounce } from "../../../../hooks/useDebounce";
import FormFieldCheckbox from "components/shared/form/FormFieldCheckbox";
import FormFieldSelect from "components/FormFieldSelect";
import { billTo } from "data/billToTypes";
import cx from "classnames";

const PUBLIC_ASSETS_BASE_URL = process.env?.REACT_APP_PUBLIC_ASSETS_URL;

export default function OrganizationDetailsEdit({ organization, orgId }) {
  const {
    watch,
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
  } = useForm();

  const [logoUrlPreview, setLogoUrlPreview] = useState(
    organization?.brandDetails?.logoUrl
  );
  const [smallLogoUrlPreview, setSmallLogoUrlPreview] = useState(
    organization?.brandDetails?.smallLogoUrl
  );

  const [isPrisidioBranded, setIsPrisidioBranded] = useState(
    organization?.isPrisidioBranded
  );

  const [allowPublicRegistration, setAllowPublicRegistration] = useState(
    organization?.allowPublicRegistration
  );

  const billToValue = watch("billingRule.billTo");

  function handleIsBrandedClick() {
    setIsPrisidioBranded(!isPrisidioBranded);
  }

  function handleAllowRegistrationClick() {
    setAllowPublicRegistration(!allowPublicRegistration);
  }

  useEffect(() => {
    if (organization?.billingRule?.billTo) {
      setValue(
        "billingRule.billTo",
        billTo.find((x) => x.value === organization?.billingRule?.billTo)
      );
    }
  }, [organization]);

  useEffect(() => {
    if (billToValue?.value !== billTo?.[1]?.value) {
      setValue("billingRule.orgBillPeriodDays", null);
    }
  }, [billToValue]);

  const { mutate, isLoading } = useMutation(
    (data) => {
      return updateOrganization(orgId, data);
    },
    {
      onSuccess: (response) => {
        history.push(`/organizations/${orgId}`);
        toast.success(
          <Message.Success
            text={`${middleEllipsis(
              response.data.name
            )} details have been updated.`}
          />
        );
      },
      onError: (error) => {
        toast.error(`${error.response.data?.errorMessage}`);
      },
    }
  );

  function onSubmit(data) {
    var mergedData = {
      ...organization,
      ...data,
      clientId: data.clientId || null,
      effectiveDate: moment(data?.effectiveDate).unix().toString(),
      endDate: data.endDate ? moment(data?.endDate).unix().toString() : null,
      subscriptionPriceIds: data.subscriptionPriceIds.filter(
        (priceId) => !!priceId
      ),
      brandDetails: {
        logoUrl: data.brandDetails.logoUrl || getValues("brandDetails.logoUrl"),
        smallLogoUrl:
          data.brandDetails.smallLogoUrl ||
          getValues("brandDetails.smallLogoUrl"),
        pageBackgroundColor:
          data.brandDetails.pageBackgroundColor ||
          getValues("brandDetails.pageBackgroundColor"),
        primaryColor:
          data.brandDetails.primaryColor ||
          getValues("brandDetails.primaryColor"),
        slogan: data.brandDetails.slogan || getValues("brandDetails.slogan"),
        sloganBackgroundColor:
          data.brandDetails.sloganBackgroundColor ||
          getValues("brandDetails.sloganBackgroundColor"),
        backgroundImageUrl:
          data.brandDetails.backgroundImageUrl ||
          getValues("brandDetails.backgroundImageUrl"),
      },
      billingRule: {
        billTo: data?.billingRule?.billTo?.value
          ? data?.billingRule?.billTo?.value
          : null,
        orgBillPeriodDays: !!data?.billingRule?.orgBillPeriodDays
          ? data?.billingRule?.orgBillPeriodDays
          : null,
      },
    };

    mutate(mergedData);
  }

  function onChangeLogoUrl(logoUrl) {
    setLogoUrlPreview(logoUrl?.target?.value);
  }

  function onChangeSmallLogoUrl(smallLogoUrl) {
    setSmallLogoUrlPreview(smallLogoUrl?.target?.value);
  }

  const debouncedLogoPreview = useDebounce(logoUrlPreview, 1000);
  const debouncedSmallLogoPreview = useDebounce(smallLogoUrlPreview, 1000);

  useEffect(() => {
    if (!isPrisidioBranded) return;
    setValue("logoUrl", `${PUBLIC_ASSETS_BASE_URL}/prisidio-logo.png`);
    setValue(
      "smallLogoUrl",
      `${PUBLIC_ASSETS_BASE_URL}/prisidio-logosmall-zach-test.png`
    );
    setValue("primaryColor", "#2742f5");
    setValue("pageBackgroundColor", "#f9f9ff");
  }, [isPrisidioBranded, setValue]);

  return (
    <div className="columns">
      <div className="column">
        <form className="is-flex is-flex-grow-1" noValidate>
          <div className="tile is-vertical mt-3">
            <div className="tile">
              <Panel>
                <article className="tile is-child is-white p-3">
                  <h3 className="pb-3">Details</h3>
                  <FormField
                    autoFocus
                    name="name"
                    type="text"
                    label="Name"
                    placeholder="koz-co-org"
                    size="normal"
                    id="organization-add-name"
                    defaultValue={organization?.name}
                    description="This is any human-readable identifier for the organization that will be used by end-users to direct them to their organization. Use hyphen-case."
                    error={errors.name}
                    inputRef={register({
                      required: "Please enter a name",
                    })}
                  />

                  <FormField
                    autoFocus
                    name="displayName"
                    type="text"
                    label="Display Name"
                    placeholder="Koz Co"
                    size="normal"
                    id="organization-add-display-name"
                    defaultValue={organization?.displayName}
                    description="This is the name that will be displayed to end-users for this organization."
                    error={errors.displayName}
                    inputRef={register({
                      required: "Please enter a display name",
                    })}
                  />

                  <div className="is-flex pt-4 pb-4">
                    <FormFieldCheckbox
                      name="allowPublicRegistration"
                      label="Allow Public Registration"
                      description=""
                      checked={allowPublicRegistration}
                      onChange={handleAllowRegistrationClick}
                      id="organization-edit-allow-public-registration-checkbox"
                      inputRef={register}
                    />
                  </div>

                  <hr />
                  <div className="is-flex pt-4 pb-4">
                    <FormFieldCheckbox
                      name="isPrisidioBranded"
                      label="Use Prisidio Branding"
                      description=""
                      checked={isPrisidioBranded}
                      onChange={handleIsBrandedClick}
                      id="organization-add-is-prisidio-branded-checkbox"
                      inputRef={register}
                    />
                  </div>

                  <FormField
                    autoFocus
                    name="brandDetails.logoUrl"
                    type="text"
                    label="Logo Url"
                    placeholder={`${PUBLIC_ASSETS_BASE_URL}/kozapproved.png`}
                    size="normal"
                    id="organization-add-logo-url"
                    defaultValue={organization?.brandDetails?.logoUrl}
                    description="This is the logo that will be displayed to end-users within this organization. The URL must use https"
                    error={errors.logoUrl}
                    onChange={onChangeLogoUrl}
                    inputRef={register({
                      required: "Please enter a logo url",
                    })}
                  />

                  <FormField
                    autoFocus
                    name="brandDetails.smallLogoUrl"
                    type="text"
                    label="Small Logo Url"
                    placeholder={`${PUBLIC_ASSETS_BASE_URL}/kozapproved.png`}
                    size="normal"
                    id="organization-add-small-logo-url"
                    defaultValue={organization?.brandDetails?.smallLogoUrl}
                    description="This will be displayed in mobile's vault selector. This should have an aspect ratio of 1:1. The URL must use https"
                    error={errors.smallLogoUrl}
                    onChange={onChangeSmallLogoUrl}
                    inputRef={register({
                      required: "Please enter a logo url",
                    })}
                  />

                  <FormField
                    autoFocus
                    name="brandDetails.primaryColor"
                    type="text"
                    label="Primary Color"
                    placeholder="#ff0000"
                    size="normal"
                    id="organization-add-primary-color"
                    defaultValue={organization?.brandDetails?.primaryColor}
                    description="This is the primary color that will be displayed to end-users for this organization. Enter a full Hex value, including #."
                    error={errors.primaryColor}
                    inputRef={register({
                      required: "Please enter a primary color",
                    })}
                  />

                  <FormField
                    name="brandDetails.pageBackgroundColor"
                    type="text"
                    label="Secondary / Background Color"
                    placeholder="#000000"
                    size="normal"
                    id="organization-add-secondary-color"
                    defaultValue={
                      organization?.brandDetails?.pageBackgroundColor
                    }
                    description="REQUIRED. This is the secondary / background color that will be displayed to end-users for this organization. Enter a full Hex value, including #."
                    error={errors.pageBackgroundColor}
                    inputRef={register({
                      required: "Please enter a secondary color",
                    })}
                  />

                  <FormField
                    name="brandDetails.backgroundImageUrl"
                    type="text"
                    label="Background Image Url"
                    placeholder={`${PUBLIC_ASSETS_BASE_URL}/kozapproved.png`}
                    size="normal"
                    id="organization-add-background-image-url"
                    defaultValue={
                      organization?.brandDetails?.backgroundImageUrl
                    }
                    description="REQUIRED. This is the background image that will be displayed on the login scree. The URL must use https"
                    error={errors.backgroundImageUrl}
                    inputRef={register}
                  />

                  <FormField
                    autoFocus
                    name="brandDetails.slogan"
                    type="text"
                    label="Slogan"
                    placeholder="slogan"
                    size="normal"
                    id="organization-edit-slogan"
                    defaultValue={organization?.brandDetails?.slogan}
                    description=""
                    error={errors.slogan}
                    inputRef={register}
                  />

                  <FormField
                    name="brandDetails.sloganBackgroundColor"
                    type="text"
                    label="Slogan Background Color"
                    placeholder="#000000"
                    size="normal"
                    id="organization-add-slogan-color"
                    defaultValue={
                      organization?.brandDetails?.sloganBackgroundColor
                    }
                    description="REQUIRED. This is the color that will appear behind the slogan text. Please use a value that works well with white text."
                    error={errors.sloganBackgroundColor}
                    inputRef={register}
                  />

                  <hr />

                  <FormField
                    name="effectiveDate"
                    type="date"
                    label="Effective Date"
                    placeholder=""
                    size="normal"
                    id="organization-edit-effective-date"
                    defaultValue={
                      organization?.effectiveDate &&
                      moment
                        .unix(organization?.effectiveDate)
                        .format("YYYY-MM-DD")
                    }
                    error={errors.effectiveDate}
                    inputRef={register({
                      message:
                        "Please enter a valid date in the following format, XX/XX/XXXX.",
                    })}
                  />

                  <FormField
                    name="endDate"
                    type="date"
                    label="End Date"
                    placeholder=""
                    size="normal"
                    id="organization-edit-end-date"
                    defaultValue={
                      organization?.endDate &&
                      moment.unix(organization?.endDate).format("YYYY-MM-DD")
                    }
                    description="OPTIONAL. What date will this organization not be available? Defaults to 12:00AM UTC of the day chosen."
                    error={errors.endDate}
                    inputRef={register({
                      message:
                        "Please enter a valid date in the following format, XX/XX/XXXX.",
                    })}
                  />

                  <hr />

                  <FormFieldSelect
                    name="billingRule.billTo"
                    options={billTo}
                    label="Bill To"
                    size="normal"
                    id="organization-edit-bill-to"
                    error={errors?.billingRule?.billTo}
                    control={control}
                    setValue={setValue}
                  />

                  <FormField
                    name="billingRule.orgBillPeriodDays"
                    type="number"
                    min={0}
                    label="Org Bill Period"
                    placeholder=""
                    size="normal"
                    disabled={billToValue?.value !== billTo?.[1]?.value}
                    id="organization-edit-billperiod-days"
                    defaultValue={organization?.billingRule?.orgBillPeriodDays}
                    description="OPTIONAL. The number of days before the customer starts getting billed."
                    error={errors?.billingRule?.orgBillPeriodDays}
                    inputRef={register}
                  />

                  <FormField
                    name="subscriptionProductId"
                    type="text"
                    label="Subscription Product Id"
                    placeholder=""
                    size="normal"
                    id="organization-edit-product-id"
                    defaultValue={organization?.subscriptionProductId}
                    description=""
                    error={errors.subscriptionProductId}
                    inputRef={register({
                      required: "Please enter a subscriptionProductId",
                    })}
                  />

                  <FormField
                    name="subscriptionPriceIds.0"
                    type="text"
                    label="Subscription Price Id #1"
                    placeholder=""
                    size="normal"
                    id="organization-edit-price-id-0"
                    defaultValue={
                      organization?.subscriptionPriceIds?.[0] ??
                      organization?.subscriptionPriceId
                    }
                    description="Stripe Price Id"
                    error={errors?.subscriptionPriceIds?.[0]}
                    inputRef={register({
                      required: "Please enter at least one subscriptionPriceId",
                    })}
                  />

                  <FormField
                    name="subscriptionPriceIds.1"
                    type="text"
                    label="Subscription Price Id #2"
                    placeholder=""
                    size="normal"
                    id="organization-edit-price-id-1"
                    defaultValue={organization?.subscriptionPriceIds?.[1]}
                    description="Stripe Price Id"
                    error={errors?.subscriptionPriceIds?.[1]}
                    inputRef={register}
                  />
                  <FormField
                    name="subscriptionPriceIds.2"
                    type="text"
                    label="Subscription Price Id #3"
                    placeholder=""
                    size="normal"
                    id="organization-edit-price-id-2"
                    defaultValue={organization?.subscriptionPriceIds?.[2]}
                    description="Stripe Price Id"
                    error={errors?.subscriptionPriceIds?.[2]}
                    inputRef={register}
                  />
                  <FormField
                    name="subscriptionPriceIds.3"
                    type="text"
                    label="Subscription Price Id #4"
                    placeholder=""
                    size="normal"
                    id="organization-edit-price-id-3"
                    defaultValue={organization?.subscriptionPriceIds?.[3]}
                    description="Stripe Price Id"
                    error={errors?.subscriptionPriceIds?.[3]}
                    inputRef={register}
                  />

                  <hr />

                  <FormField
                    name="customDns"
                    type="text"
                    label="Custom DNS"
                    placeholder="koz.prisid.io"
                    size="normal"
                    id="organization-edit-custom-dns"
                    defaultValue={organization?.customDns}
                    description="Custom DNS for the Org.  Will generate signup and login urls in the API.  Do not include https://www"
                    error={errors.customDns}
                    inputRef={register({
                      required: "Please enter a Custom DNS value",
                    })}
                  />

                  <FormField
                    name="clientId"
                    type="text"
                    label="Client Id"
                    placeholder=""
                    size="normal"
                    id="organization-edit-client-id"
                    defaultValue={organization?.clientId}
                    description="Provided by Auth0 when an app is created for the organization"
                    error={errors.clientId}
                    inputRef={register}
                  />

                  <div className="field pb-3">
                    <label className="label has-text-size-normal mb-1">
                      Updated
                    </label>
                    <div className="control">
                      <p
                        className={`has-text-karman100 is-font-size-large has-text-weight-medium ${styles.value}`}
                      >
                        {!!organization?.updatedAt
                          ? moment.unix(organization?.updatedAt).format("l")
                          : "--"}
                      </p>
                    </div>
                  </div>
                  <div
                    className={cx(
                      styles.sticky,
                      "field is-grouped is-flex is-justify-content-flex-end pb-4"
                    )}
                  >
                    <Button
                      color="secondary"
                      text="Cancel"
                      hasSecondaryMargin
                      id="organization-edit-cancel-btn"
                      onClick={() => history.push(`/organizations/${orgId}`)}
                      type="button"
                      disabled={isLoading}
                    />
                    <Button
                      text="Save"
                      id="organization-edit-submit-btn"
                      onClick={handleSubmit(onSubmit)}
                      isLoading={isLoading}
                      disabled={isLoading}
                    />
                  </div>
                </article>
              </Panel>
            </div>
          </div>
        </form>
      </div>
      <div className="column">
        <div className="tile is-vertical mt-3">
          <div className="tile">
            <Panel>
              <article className="tile is-child is-white p-3">
                <h3 className="pb-3">Brand Details Preview</h3>
                Logo:{" "}
                <img
                  src={debouncedLogoPreview}
                  alt="Logo Preview"
                  className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
                />
                Small Logo:
                <img
                  src={debouncedSmallLogoPreview}
                  alt="Small Logo Preview"
                  className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
                />
              </article>
            </Panel>
          </div>
        </div>
      </div>
    </div>
  );
}
