import React from "react";
import { history } from "util/router";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { FormProvider, useForm } from "react-hook-form";
import Modal from "components/shared/modal/Modal";
import { addOrganization } from "stores/organizationsStore";
import AttributeValueInputs from "./AttributeValueInputs";
import Message from "components/shared/toast/Message";
import { middleEllipsis } from "util/helpers";
import moment from "moment";

export default function OrganizationAddModal({ onDone, initialName = "" }) {
  const methods = useForm();

  const { mutate, isLoading } = useMutation(
    (data) => {
      return addOrganization(data);
    },
    {
      onSuccess: (response) => {
        toast.success(
          <Message.Success
            text={`${middleEllipsis(response.data.name)} has been added.`}
          />
        );
        history.push(`/organizations/${response?.data.orgId}`);
      },
      onError: (error) => {
        toast.error(`${error?.response?.data?.errorMessage}`);
      },
    }
  );

  function onSubmit(data) {
    let org = {
      subscriptionProductId: data.subscriptionProductId,
      subscriptionPriceIds: data.subscriptionPriceIds.filter(priceId => !!priceId),
      allowPublicRegistration: data?.allowPublicRegistration,
      displayName: data.displayName,
      customDns: data.customDns,
      name: data.name,
      effectiveDate: moment(data?.effectiveDate).unix().toString(),
      endDate: data.endDate ? moment(data?.endDate).unix().toString() : null,
      isPrisidioBranded: data.isPrisidioBranded,
      clientId: data.clientId || null,
      brandDetails: {
        logoUrl: data.logoUrl || methods.getValues("logoUrl"),
        smallLogoUrl: data.smallLogoUrl || methods.getValues("smallLogoUrl"),
        pageBackgroundColor:
          data.pageBackgroundColor || methods.getValues("pageBackgroundColor"),
        primaryColor: data.primaryColor || methods.getValues("primaryColor"),
        slogan: data.slogan || methods.getValues("slogan"),
        sloganBackgroundColor:
          data.sloganBackgroundColor ||
          methods.getValues("sloganBackgroundColor"),
        backgroundImageUrl:
          data.backgroundImageUrl || methods.getValues("backgroundImageUrl"),
      },
      billingRule: {
        billTo: data?.billingRule?.billTo?.value ? data?.billingRule?.billTo?.value : null,
        orgBillPeriodDays: !!data?.billingRule?.orgBillPeriodDays ? data?.billingRule?.orgBillPeriodDays : null
      }
    };

    mutate(org);
  }

  return (
    <Modal onClose={() => onDone()}>
      <Modal.Header title="Add Organization" onClose={() => onDone()} />
      <Modal.Body hasScroll>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
            autoComplete="off"
          >
            <AttributeValueInputs initialValue={initialName} />
          </form>
        </FormProvider>
      </Modal.Body>
      <Modal.Footer
        onCancel={() => onDone()}
        onSubmit={methods.handleSubmit(onSubmit)}
        submitText="Save"
        isSubmitting={isLoading}
      />
    </Modal>
  );
}
