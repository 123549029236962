export const billTo = [
    {
        label: "-- Select an option --",
        value: "",
    },
    {
        label: "Customer",
        value: "CUSTOMER",
        key: "CUSTOMER",
    },
    {
        label: "Organization",
        value: "ORGANIZATION",
        key: "ORGANIZATION",
    },
];